// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom'; // Importiere den Router
import App from './App';
import theme from './theme';
import { LanguageProvider } from './components/LanguageContext';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Setzt eine neutrale Basis für das Styling */}
      <Router>
        <LanguageProvider>
          <App />
        </LanguageProvider>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
