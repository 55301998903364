// src/pages/Privacy.tsx
import React from 'react';
import { Box, Typography } from '@mui/material';
import '../styles/Privacy.css';

const Privacy: React.FC = () => {
  return (
    <Box sx={{ 
        scrollSnapAlign: 'start',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        color: '#333',
        p: 6,
        textAlign: 'left' }} 
        data-testid="privacy">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h1" component="h1" gutterBottom>
          Datenschutz
        </Typography>
        <Typography variant="body1" paragraph>
          Wir freuen uns über Ihr Interesse an unserer Website. Der Schutz Ihrer Daten ist uns wichtig. Nachfolgend informieren wir Sie ausführlich über den Umgang mit Ihren Daten.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          1. Verantwortliche Stelle
        </Typography>
        <Typography variant="body1" paragraph>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>iucon GmbH</strong>
          <br />
          Essener Straße 5
          <br />
          46047 Oberhausen
          <br />
          Deutschland
        </Typography>
        <Typography variant="body1" paragraph>
          Telefon: +49 (0) 208 780 192 – 12
          <br />
          E-Mail: info@iucon.com
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          2. Datenschutzbeauftragter:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Michael Vogelbacher</strong>
          <br />
          consileo GmbH & Co. KG
          <br />
          Bahnhofstr. 5
          <br />
          53572 Unkel
          <br />
          Deutschland
        </Typography>
        <Typography variant="body1" paragraph>
          E-Mail: datenschutz@iucon.de
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          3. Zweckbestimmung und Datensparsamkeit
        </Typography>
        <Typography variant="body1" paragraph>
          iucon bietet individuelle und passgenaue Software-Lösungen für Ihr Unternehmen. Wir erheben und verwenden Ihre personenbezogenen Daten ausschließlich im Rahmen der Bestimmungen der Verordnung zum Datenschutzrecht der Europäischen Union und der Bundesrepublik Deutschland. Im Folgenden unterrichten wir Sie über Art, Umfang und Zwecke der Erhebung und Verwendung personenbezogener Daten. Eine darüber hinausgehende Verarbeitung zu anderen Zwecken als den nachfolgend bestimmten erfolgt nicht. Sie können diese Unterrichtung jederzeit von dieser Webseite abrufen.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          4. Rechtmäßigkeit der Verarbeitung
        </Typography>
        <Typography variant="body1" paragraph>
          Ihre Daten werden gemäß folgenden Rechtmäßigkeitsgrundsätzen von uns verarbeitet (insb. erhoben, verwendet und gespeichert):
          <br/>
          <ol className='lower-alpha'>
            <li>gemäß Art. 6 Abs. 1 lit. b) DSGVO um Ihre Anfragen zu beantworten, Ihre Aufträge zu bearbeiten oder Ihnen Zugang zu bestimmten Informationen oder Angeboten zu verschaffen.</li>
            <li>gemäß Art. 6 Abs. 1 lit. f) DSGVO zur Wahrung berechtigter Interessen von uns oder Dritten, auch im Rahmen von Werbung oder Marktforschung, zur Verbesserung der Dienstleistungen und Services, dem Angebot von maßgeschneiderten Produkten oder im Rahmen von Bonitätsauskünften.</li>
            <li>gemäß Art. 6 Abs. 1 lit. a) DSGVO im Rahmen Ihrer Einwilligung, sofern Sie uns eine solche erteilt haben.</li>
            <li>gemäß Art. 6 Abs. 1 lit. c) DSGVO aufgrund gesetzlicher Vorgaben, die uns als Unternehmen treffen, insb. handels- und steuerrechtliche Vorgaben.</li>
          </ol>
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          5. Datenübermittlung und -protokollierung zu systeminternen und statistischen Zwecken
        </Typography>
        <Typography variant="body1" paragraph>
          Ihr Internet-Browser übermittelt beim Zugriff auf unsere Webseite aus technischen Gründen automatisch Daten an unseren Webserver. Es handelt sich dabei unter anderem um Datum und Uhrzeit des Zugriffs, URL der verweisenden Webseite, abgerufene Datei, Menge der gesendeten Daten, Browsertyp und -version, Betriebssystem sowie Ihre IP-Adresse. Diese Daten werden gespeichert. Eine Zuordnung dieser Daten zu einer bestimmten Person ist uns nicht möglich. Diese Daten werden zu statistischen Zwecken ausgewertet und im Anschluss gelöscht.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          6. Nutzungsdaten
        </Typography>
        <Typography variant="body1" paragraph>
          Wir erheben und verwenden personenbezogene Daten von Ihnen, soweit dies erforderlich ist, um die Inanspruchnahme unseres Internetangebotes zu ermöglichen (Nutzungsdaten). Dazu gehören insbesondere Merkmale zu Ihrer Identifikation und Angaben zu Beginn und Ende sowie des Umfangs der Nutzung unseres Angebotes.
        </Typography>
        <Typography variant="body1" paragraph>
          Für Zwecke der Werbung, der Marktforschung und zur bedarfsgerechten Gestaltung unseres Internetangebotes dürfen wir bei Verwendung von Pseudonymen Nutzungsprofile erstellen. Sie haben das Recht, dieser Verwendung Ihrer Daten zu widersprechen. Die Nutzungsprofile dürfen wir nicht mit Daten über den Träger des Pseudonyms zusammenführen.
        </Typography>
        <Typography variant="body1" paragraph>
          Darüber hinaus werden Daten ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          7. Betroffene Personen und Datenkategorien
        </Typography>
        <Typography variant="body1" paragraph>  
          Betroffen sind Kunden, Besucher der Website und Geschäftspartner sowie Mitarbeiter. Hier werden Name, Vorname, gegebenenfalls Adresse und IP erfasst. Allerdings nur im Umfang, wie unter Zweckbestimmung und Datensparsamkeit beschrieben.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          8. Löschung von Daten
        </Typography>
        <Typography variant="body1" paragraph>  
          Fällt der Zweck weg und besteht auch keine gesetzliche Aufbewahrungsfrist, werden die entsprechenden personenbezogenen Daten gelöscht. Grundsätzlich werden die Daten nur für die Dauer der oben genannten Zwecke gespeichert.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          9. Datenübermittlung an Drittstaaten
        </Typography>
        <Typography variant="body1" paragraph>  
          Eine Datenübermittlung an Drittstaaten ist nicht angedacht und findet nicht statt.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          10. Betroffenenrechte
        </Typography>
        <Typography variant="body1" paragraph>  
          Sie haben jederzeit die Möglichkeit und das Recht sich bei der verantwortlichen Stelle (siehe oben) zu melden und Auskunft darüber zu verlangen, wie Ihre Daten verarbeitet werden. Zudem haben Sie ein Recht auf Berichtigung Ihrer Daten. Sie haben das Recht auf Einschränkung der Verarbeitung, also nur noch eine Verarbeitung von Teilinformationen zuzulassen. Zudem haben Sie jederzeit das Recht der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen. Wir prüfen dann, inwieweit der Nichtverarbeitung gesetzliche Weitergabe- und Verarbeitungspflichten entgegenstehen und informiert Sie entsprechend. Sie haben ein Recht auf Datenübertragbarkeit, d.h. dass wir Ihnen Ihre personenbezogenen Daten in maschinenlesbarer Form in einem gängigen von uns bestimmten Format auf Anforderung übergeben.
        </Typography>
        <Typography variant="body1" paragraph>  
          Zudem haben Sie ein Beschwerderecht bei der für Ihr Land zuständigen Aufsichtsbehörde für den Datenschutz, wenn Sie der Meinung sind, dass wir mit Ihren personenbezogenen Daten nicht ordnungsgemäß und im Sinne dieser Erklärung umgehen.
        </Typography>
        <Typography variant="body1" paragraph>  
          Haben Sie uns Ihre Einwilligung zur Verarbeitung Ihrer Daten gegeben, können Sie diese Einwilligung jederzeit widerrufen.
        </Typography>
        <Typography variant="body1" paragraph>  
          Erhalten Sie von uns Werbung zugesendet, so steht Ihnen auch hierfür jederzeit das Widerspruchsrecht zu keine Werbung mehr von uns zu erhalten.
        </Typography>
      </Box>
    </Box>
  );
};

export default Privacy;
