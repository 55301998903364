// src/components/Navbar.tsx
import React from 'react';
import { AppBar, Toolbar, Button, IconButton, Drawer, List, ListItem, ListItemText, Box, useMediaQuery, useTheme, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Diversity1Sharp';
import ProjectIcon from '@mui/icons-material/AccountTree';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from './LanguageContext';
import '../styles/Navbar.css';

const Navbar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Mobile View width <= 900px
  const navigate = useNavigate(); // useNavigate Hook zum Programmieren der Navigation

  const { language, setLanguage } = useLanguage();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLanguageToggle = () => {
    setLanguage(language === 'de' ? 'en' : 'de');
  };

  // Funktion zum Scrollen nach oben und zur Route navigieren
  const handleScrollAndNavigate = (href: string) => {
    // Temporäres Element erstellen und zum Dokument hinzufügen
    const topElem = document.querySelectorAll('h3')[0];

    // Scrollen zum temporären Element
    topElem?.scrollIntoView({ behavior: 'auto', block: 'start' });
    
    // Nach einer kurzen Verzögerung navigieren
    setTimeout(() => {
      setDrawerOpen(false);
      navigate(href); // Navigiere zur angegebenen Route nach dem Scrollen
    }, 0); // Timeout sollte länger als die Dauer der Scroll-Animation sein
  };

  const navItems = [
    { text: 'Home', href: '/', icon: <HomeIcon />, testId: 'home-link' },
    { text: 'Projekte', href: '/projects', icon: <ProjectIcon />, testId: 'projects-link' },
    { text: 'Karriere', href: '/recruiting', icon: <WorkIcon />, testId: 'recruiting-link' },
    { text: 'SIP Scootershop', href: '/sip-scootershop', icon: <TwoWheelerIcon />, testId: 'sip-link' }
  ];

  return (
    <AppBar position="sticky" className="navbar-appbar">
      <Toolbar data-testid="navbar">
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              aria-label="menu"
              onClick={handleDrawerToggle}
              className="navbar-icon-button"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={handleDrawerToggle}
              classes={{ paper: 'navbar-drawer-paper' }}
            >
              <List>
                {navItems.map((item) => (
                  <ListItem
                    button
                    component="a" // Ändere dies von Link zu "a", um das Click-Event zu ermöglichen
                    key={item.text}
                    onClick={() => handleScrollAndNavigate(item.href)}
                    data-testid={item.testId} // Hinzugefügt für Tests
                  >
                    <span className={`navbar-drawer-icon ${item.text.replace(/\s+/g, '').toLowerCase()}`}>
                      {item.icon}
                    </span>                    
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
                <ListItem button onClick={handleLanguageToggle}>
                  <ListItemText
                    primary={
                      <Box display="flex" alignItems="center">
                        <Typography variant="body1" style={{ fontWeight: language === 'de' ? 'bold' : 'normal' }}>
                          DE
                        </Typography>
                        <Typography variant="body1" style={{ margin: '0 8px' }}>|</Typography>
                        <Typography variant="body1" style={{ fontWeight: language === 'en' ? 'bold' : 'normal' }}>
                          EN
                        </Typography>
                      </Box>
                    }
                  />
                </ListItem>
              </List>
            </Drawer>
            <span style={{ width: '100%', textAlign: 'right', fontWeight: 'bold' }}>iucon GmbH</span>
          </>
        ) : (
          <Box className="navbar-desktop">
            {navItems.map((item) => (
              <Button
                key={item.text}
                startIcon={item.icon}
                className="navbar-button"
                data-testid={item.testId}
                onClick={() => handleScrollAndNavigate(item.href)} // Füge die Funktion hier hinzu
              >
                {item.text}
              </Button>
            ))}
            <Button
              onClick={handleLanguageToggle}
              className="navbar-button"
              style={{ fontWeight: 'bold' }}
            >
              {language === 'de' ? (
                <>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>DE</Typography>&nbsp;|&nbsp;
                  <Typography variant="body1">EN</Typography>
                </>
              ) : (
                <>
                  <Typography variant="body1">DE</Typography>&nbsp;|&nbsp;
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>EN</Typography>
                </>
              )}
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
