// src/App.tsx
import React , { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Importiert den useLocation-Hook
import Navbar from './components/Navbar'; // Importiert die Navbar-Komponente
import Footer from './components/Footer'; // Importiert die Footer-Komponente
import { Box } from '@mui/material'; // Importiert die Box-Komponente von MUI für das Layout
import { Route, Routes } from 'react-router-dom'; // Importiert Router-Komponenten für das Routing
import GenericPage from './pages/GenericPage'; // Importiert die generische Content-Seite
import Imprint from './pages/Imprint'; // Importiert die Impressum-Seite
import Privacy from './pages/Privacy'; // Importiert die Datenschutz-Seite
import { useLanguage } from './components/LanguageContext';
import { textContentDE } from './data/textContentDE';
import { textContentEN } from './data/textContentEN';

const App: React.FC = () => {
  const { language } = useLanguage();
  const location = useLocation(); // Holt den aktuellen Standort (Route)

  // Sprachdaten auswählen
  const slideData = language === 'en' ? textContentEN : textContentDE;

  // Scrollt nach oben bei jedem Seitenwechsel
  useEffect(() => {
    setTimeout(() => {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
  }, 5);
  }, [location]);

  return (
    <Box sx={{ scrollSnapType: 'y mandatory', overflowY: 'scroll', height: '100vh' }}>
      {/* Der Box-Container sorgt für das Scrollen der Seite und stellt sicher, dass das Snapping vertikal erfolgt */}
      <Navbar />
      {/* Navbar wird auf allen Seiten angezeigt */}
      <Routes>
        {/* Definiert die Routen der Anwendung */}
        <Route
          path="/"
          element={<GenericPage showHero slideData={slideData.home} pageTitle={language === 'en' ? "Home" : "Home"} dataTestId='home' />}
        />        
        <Route
          path="/projects"
          element={<GenericPage showHero={false} slideData={slideData.projects} pageTitle={language === 'en' ? "Projects" : "Projekte"} dataTestId='project' />}
        />
        <Route
          path="/sip-scootershop"
          element={<GenericPage showHero={false} slideData={slideData.sip} pageTitle={language === 'en' ? "SIP Scootershop" : "SIP Scootershop"} dataTestId='sip' />}
        />
        <Route
          path="/recruiting"
          element={<GenericPage showHero={false} slideData={slideData.recruiting} pageTitle={language === 'en' ? "Recruiting" : "Recruiting"} dataTestId='recruiting' />}
        />          <Route path="/imprint" element={<Imprint />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <Box sx={{ scrollSnapAlign: 'start' }}>
        {/* Footer-Box, die das Scroll-Snap-Verhalten für den Footer anpasst */}
        <Footer />
        {/* Footer wird immer am Ende der Seite angezeigt */}
      </Box>
    </Box>
  );
};

export default App;
