// src/pages/Imprint.tsx
import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Imprint: React.FC = () => {
  return (
    <Box sx={{ 
        scrollSnapAlign: 'start',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        color: '#333',
        p: 6,
        textAlign: 'left' }} 
        data-testid="imprint">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h2" component="h1" gutterBottom>
        Impressum
        </Typography>
        <Typography variant="body1" paragraph>
          Verantwortlich für den Inhalt dieser Website gemäß § 6 TDG / MDStV:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>iucon GmbH</strong>
          <br />
          Essener Straße 5
          <br />
          46047 Oberhausen
          <br />
          Deutschland
        </Typography>
        <Typography variant="body1" paragraph>
          Telefon: +49 (0) 208 780 192 – 12
          <br />
          E-Mail: info@iucon.de
        </Typography>
        <Typography variant="body1" paragraph>
          Geschäftsführer: Björn Stude, Stefan Schwedt
        </Typography>
        <Typography variant="body1" paragraph>
          Gerichtsstand Oberhausen<br />
          Registergericht Amtsgericht Oberhausen, HRB 37141<br />
          <br />
          Steuernummer 124/5718/2112<br />
          Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz DE236381181
        </Typography>
        <Typography variant="body1" paragraph>
          Inhaltlich Verantwortlicher gemäß § 10 Absatz 3 MDStV Björn Stude
        </Typography>
        <Typography variant="body1" paragraph>
          Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
        </Typography>
        <Typography variant="body1" paragraph>
          <Link to="/privacy">Zur Datenschutzerklärung</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Imprint;
