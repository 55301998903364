import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useLanguage } from './LanguageContext';
import '../styles/DataTransferAnimation.css';

// Funktion zum Erzeugen der Punkte für die Animation
const renderDots = (count: number) => {
  return Array(count).fill(null).map((_, i) => (
    <div
      key={i}
      className="dot"
      style={{
        animationDelay: `${i * 0.5}s`, // Verzögerung der Animation für jeden Punkt
      }}
    />
  ));
};

const DataTransferAnimation: React.FC = () => {
  const theme = useTheme();
  const { language } = useLanguage();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Text basierend auf der Sprache
  const titleText = language === 'de'
    ? 'Effiziente Warenflusssteuerung durch integrierte ERP- und WMS-Lösungen'
    : 'Efficient Goods Flow Control through Integrated ERP and WMS Solutions';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row', // Anordnung der Boxen nebeneinander oder untereinander
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2, // Abstand zwischen den Boxen
        p: 2,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        maxWidth: '80%', // Maximalbreite für den Container
        maxHeight: isMobile ? '80vh' : 'none', // Maximale Höhe auf Mobile
        margin: '0 auto', // Zentriert den Container horizontal
      }}
    >
      {/* Box mit Bild und Beschreibung */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: isMobile ? '100%' : '33%', // Breite auf 100% im mobilen View
          maxWidth: isMobile ? 'none' : '500px', // Maximalbreite auf Desktop
          maxHeight: isMobile ? '33vh' : 'none', // Maximale Höhe auf Mobile
          textAlign: 'center',
        }}
      >
        <Box
          component="img"
          src="/img/logo_vector_600dpi.webp"
          alt="iucon Logo"
          sx={{
            maxWidth: '100%', // Bild passt sich der Breite der Box an
            height: 'auto', // Höhe wird automatisch angepasst, um das Seitenverhältnis zu bewahren
          }}
        />
        <Typography
          variant="h6"
          className='hero-text'
          sx={{
            mt: 1, // Abstand nach oben
            color: "GrayText"
          }}
        >
          {titleText}
        </Typography>
      </Box>

      {/* Box mit Punkten */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: isMobile ? '100%' : '33%', // Breite auf 100% im mobilen View
          maxWidth: isMobile ? 'none' : '200px', // Maximalbreite auf Desktop
          maxHeight: isMobile ? '33vh' : '200px', // Maximale Höhe auf Mobile und Desktop
          overflow: 'visible', // Überlauf sichtbar, um Animationen nicht zu beschneiden
        }}
      >
        <div className="data-transfer">
          {/* Erste Reihe von Punkten */}
          <div className="row first">
            {renderDots(20)} {/* Render die Punkte */}
          </div>
          {/* Zweite Reihe von Punkten */}
          <div className="row second">
            {renderDots(20)} {/* Render die Punkte */}
          </div>
          {/* Dritte Reihe von Punkten */}
          <div className="row third">
            {renderDots(20)} {/* Render die Punkte */}
          </div>
        </div>
      </Box>

      {/* Box mit Monitor-Bild */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: isMobile ? '100%' : '33%', // Breite auf 100% im mobilen View
          maxWidth: isMobile ? '200px' : '200px', // Maximalbreite auf Desktop
          maxHeight: isMobile ? '200px' : '200px', // Maximale Höhe auf Mobile und Desktop
        }}
      >
        <Box
          component="img"
          src="/img/monitor-with-scooter.webp"
          alt="Monitor mit Vespa"
          sx={{
            maxWidth: '100%', // Bild passt sich der Breite der Box an
            height: 'auto', // Höhe wird automatisch angepasst, um das Seitenverhältnis zu bewahren
          }}
        />
      </Box>
    </Box>
  );
};

export default DataTransferAnimation;
