import { Handshake, TwoWheeler, ManageAccounts, AccountTree, Star, Group, GroupAdd, Directions, Wysiwyg, Webhook, FactCheck, Smartphone, RepeatOneOn, AddReaction, Stars, Loyalty } from '@mui/icons-material';

const iconMapping: Record<string, JSX.Element> = {
  Handshake: <Handshake />,
  TwoWheeler: <TwoWheeler />,
  ManageAccounts: <ManageAccounts />,
  AccountTree: <AccountTree />,
  Star: <Star />,
  Group: <Group />,
  GroupAdd: <GroupAdd />,
  Directions: <Directions />,
  Wysiwyg: <Wysiwyg />,
  Webhook: <Webhook />,
  FactCheck: <FactCheck />,
  Smartphone: <Smartphone />,
  RepeatOneOn: <RepeatOneOn />,
  AddReaction: <AddReaction />,
  Stars: <Stars />,
  Loyalty: <Loyalty />,
};

export default iconMapping;
