import React from 'react';
import { Box, Typography, Link, List, ListItem, ListItemText } from '@mui/material';
import '../styles/Footer.css'; // Verwende dies nur für zusätzliche CSS-Styling, falls benötigt

const Footer: React.FC = () => {
  return (
    <Box className="footer" sx={{ bgcolor: 'primary.main', color: 'text.primary', p: 4 }} data-testid="footer">
      <Box 
        className="footer-content"
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          maxWidth: '1200px',
          margin: '0 auto',
          fontSize: '0.875rem' // Textgröße wird global gesetzt
        }}
      >
        {/* Kontakt Section */}
        <Box className="footer-column" sx={{ mb: { xs: 4, md: 0 } }}>
          <Typography variant="h6" gutterBottom>
            Kontakt
          </Typography>
          <Typography variant="body2">iucon GmbH</Typography>
          <Typography variant="body2">Essener Straße 5</Typography>
          <Typography variant="body2">46047 Oberhausen</Typography>
          <Typography variant="body2">Telefon: +49 (0) 208 780 192 – 12</Typography>
          <Typography variant="body2">
            E-Mail: <Link href="mailto:info@iucon.com" color="inherit">info@iucon.com</Link>
          </Typography>
        </Box>

        {/* Sitemap Section */}
        <Box className="footer-column" sx={{ mb: { xs: 4, md: 0 } }}>
          <Typography variant="h6" gutterBottom>
            Sitemap
          </Typography>
          <List disablePadding>
            <ListItem dense disableGutters>
              <ListItemText>
                <Link href="/" color="inherit">Home</Link>
              </ListItemText>
            </ListItem>
            <ListItem dense disableGutters>
              <ListItemText>
                <Link href="/projects" color="inherit">Projekte</Link>
              </ListItemText>
            </ListItem>
            <ListItem dense disableGutters>
              <ListItemText>
                <Link href="/recruiting" color="inherit">Karriere</Link>
              </ListItemText>
            </ListItem>
            <ListItem dense disableGutters>
              <ListItemText>
                <Link href="/sip-scootershop" color="inherit">SIP Scootershop</Link>
              </ListItemText>
            </ListItem>
          </List>
        </Box>

        {/* Rechtliches Section */}
        <Box className="footer-column">
          <Typography variant="h6" gutterBottom>
            Rechtliches
          </Typography>
          <List disablePadding>
            <ListItem dense disableGutters>
              <ListItemText>
                <Link href="/imprint" color="inherit" data-testid="imprint-link">Impressum</Link>
              </ListItemText>
            </ListItem>
            <ListItem dense disableGutters>
              <ListItemText>
                <Link href="/privacy" color="inherit" data-testid="privacy-link">Datenschutz</Link>
              </ListItemText>
            </ListItem>
            <ListItem dense disableGutters>
              <ListItemText>
                <br />
              </ListItemText>
            </ListItem>
            <ListItem dense disableGutters>
              <ListItemText>
                <Typography variant="body2">
                  &copy; 2024 iucon GmbH - Made in Oberhausen with ❤️
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
