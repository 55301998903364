// src/components/Hero.tsx
import React from 'react';
import { Box } from '@mui/material';
import DataTransferAnimation from './DataTransferAnimation';
import '../styles/Hero.css';

const Hero: React.FC = () => {
  return (
    <Box className="hero-container" id="home">
      <DataTransferAnimation />
    </Box>
  );
};

export default Hero;
